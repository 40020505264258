import React from "react";
import SEO from "../../components/seo";
import HorizontalLine from "../../components/horizontal-line";

const AboutUs = (props) => (
    <div location={props.location}>
        <SEO title="About" language="en"/>
        <div className="about">
            <div className="container">
                <div className="about__title">
                    <HorizontalLine classname='black'/>
                    <h2 className="about-us-title">
                        Who are we and why it is worth <br/>
                        cooperating with us?
                    </h2>
                    <HorizontalLine classname="black"/>
                </div>
                <div className="about__description">
                    <div className="squares-column">
                        <div className="square white"/>
                        <div className="square orange"/>
                        <div className="square white"/>
                    </div>
                    <div className="about__feature">
                        <p>
                            We develop. We design. We optimize for search engines and service websites. We focus
                            primarily on effective communication, listen carefully, that's why we co-create our projects
                            with clients.<br/>
                            We operate locally in Poland and work globally.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            We are distinguished by a well-integrated team, highly specialized equipment, knowledge
                            supported by a certificate of qualifications supported by creative minds. We are constantly
                            motivated by innovation and personal development is our second name.<br/>
                            Each day presents new challenges for us.<br/>
                            Every day, based on several years of experience, we work towards the success of our clients.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            In our work, we strive to satisfy even the most demanding customers. Fast and efficient
                            implementation of projects as well as proper servicing and maintenance of websites allow us
                            reliable equipment that offers a wide range of possibilities. We realize how important
                            timeliness and transparency in action is. Do you want to have a website that shows the
                            individuality of the brand? Contact us and trust our specialists!
                        </p>
                    </div>
                    <div className={`my-photo`}/>
                </div>
            </div>
        </div>
    </div>
);

export default AboutUs;
